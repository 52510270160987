:root {
  --transition-default: all ease-in-out 0.16s;
  /* Fonts */
  --font-base: "Inter", Helvetica, Arial, Tahoma, Geneva, Verdana, sans-serif;
  --font-accent: "Roboto Mono", monospace;
  /* Colors */
  --color-accent-r: #ff5641;
  --color-accent-b: #4f41ff;
  --color-accent-y: #ffc700;
  --color-light: #fff;
  /* Background */
  --bg-transparent-dark: rgba(0, 0, 0, 0.32);
  --bg-transparent-light: rgba(0, 0, 0, 0.06);
}

#app[data-theme="light"] {
  /* Background */
  --bg-light: #ccc;
  --bg-default: #ddd;
  --bg-dark: #efefef;
  /* Text */
  --txt-light: #999;
  --txt-default: #222;
  --txt-dark: #111;
}

#app[data-theme="dark"] {
  /* Background */
  --bg-light: #333;
  --bg-default: #222;
  --bg-dark: #111;
  /* Text */
  --txt-light: #999;
  --txt-default: #eee;
  --txt-dark: #fff;
}

@media (prefers-color-scheme: light) {
}

@media (prefers-color-scheme: dark) {
}

/* Rest */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* General */
* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

html,
body {
  font-size: 62.5%;
  font-family: var(--font-base);
  margin: 0;
  padding: 0;
}
